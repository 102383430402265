<template>
  <v-app>
    <loading
      :active.sync="loading"
      :can-cancel="parameters.loading.canCancel"
      :color="parameters.loading.color"
      :loader="parameters.loading.loader"
      :width="parameters.loading.width"
      :height="parameters.loading.height"
      :background-color="parameters.loading.backgroundColor"
      :opacity="parameters.loading.opacity"
      :z-index="parameters.loading.zIndex"
    />
    <v-snackbar
      v-model="notificationStatus"
      :timeout="parameters.notification.timeout"
      top
      right
      :class="
        notificationType
          ? '__snackbar snackbar-' + notificationType
          : '__snackbar'
      "
    >
      <template v-slot:default>
        <div class="d-flex align-start">
          <div>
            <v-icon
              :color="notificationType"
              :size="notificationType === 'error' ? 17 : 40"
              class="mr-1"
            >
              <template v-if="notificationType === 'error'">
                $vuetify.icons.alertError
              </template>
              <template v-else-if="notificationType === 'success'">
                $vuetify.icons.alertSuccess
              </template>
            </v-icon>
          </div>
          <div class="px-3">
            <div
              class="txt fw-700 fs-14 lh-20 mb-1"
              :class="notificationType === 'error' ? 'c-error' : 'c-gray-900'"
            >
              <template v-if="notificationTitle">
                {{ notificationTitle }}
              </template>
              <template v-else>
                <template v-if="notificationType === 'error'">
                  Ошибка
                </template>
                <template v-else-if="notificationType === 'success'">
                  Успешно
                </template>
              </template>
            </div>
            <div
              v-html="notificationText"
              style="white-space: pre-line"
              class="txt fw-400 fs-14 lh-20"
              :class="notificationType === 'error' ? 'c-error' : 'c-gray-600'"
            />
          </div>
        </div>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="notificationStatus = false">
          <v-icon
            size="10"
            :color="notificationType === 'error' ? 'error' : 'gray500'"
          >
            $vuetify.icons.close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/css/app";
</style>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
moment.locale("ru");

Vue.mixin({
  data: () => ({
    defaultImage: require("@/assets/img/default-image.svg"),
    selectGlobal: {
      roles: [
        { value: "manager", text: "Менеджер" },
        { value: "credit_administrator", text: "Кредитный администратор" },
        { value: "auditor", text: "Аудитор" },
      ],
    },
    formRules: {
      required: (value) => !!value || "Обязательное поле.",
      requiredList: (value) => value.length > 0 || "Обязательное поле.",
      requiredList1: (value) =>
        (value && value.length > 0) || "Обязательное поле.",
      counter8: (value) =>
        (value && value.length >= 8) || "Минимум 8 символов.",
      bin: (value) =>
        (value && value.length === 12) || "Должен быть 12 символов.",
      binMax: (value) =>
        (value || "").length <= 12 || "Должен быть 12 символов.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Некорректный e-mail.";
      },
      number: (value) => {
        const pattern = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
        return pattern.test(value) || "Некорректное число";
      },
      integer: (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Некорректное число";
      },
      phone: (value) => {
        return (
          (value && value.match(/\d/g).length === 11) || "Некорректный телефон"
        );
      },
      intNumber: (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Некорректное число";
      },
      requiredFile: (value) => value.length > 0 || "Обязательно",
    },
  }),
  methods: {
    findNode(id, nodes) {
      let i, result;
      for (i = 0; i < nodes.length; i += 1) {
        if (id === nodes[i].id) {
          return nodes[i];
        } else {
          if ("children" in nodes[i]) {
            result = this.findNode(id, nodes[i].children);
            if (result) {
              return result;
            }
          }
        }
      }
      return false;
    },
    findNodeWithAlternateId(_id, nodes) {
      let i, result;
      for (i = 0; i < nodes.length; i += 1) {
        if (_id === nodes[i]._id) {
          return nodes[i];
        } else {
          if ("children" in nodes[i]) {
            result = this.findNodeWithAlternateId(_id, nodes[i].children);
            if (result) {
              return result;
            }
          }
        }
      }
      return false;
    },
    getPercentage(x, y) {
      return (x / y) * 100;
    },
    getPixel(x, y) {
      return (y * x) / 100;
    },
    isObject(obj) {
      return obj && obj.constructor === Object;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isInteger($event) {
      if (
        $event.charCode === 0 ||
        /\d/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      }
      $event.preventDefault();
    },
    openInNewTab(url) {
      window.open(url, "_blank");
    },
    logout() {
      this.$store.dispatch("user/logoutUser", {}, { root: true }).then(() => {
        this.$router.push({ name: "Login" });
      });
    },
    isEmpty(str) {
      return !str;
    },
    readFile(file) {
      return new Promise((resolve) => {
        if ("extension" in file) {
          return resolve(file);
        } else {
          let fileReader = new FileReader();
          fileReader.onload = () => {
            let extension = file.name
              .split(".")
              [file.name.split(".").length - 1].toLowerCase();
            return resolve({
              title: file.name,
              data: fileReader.result,
              size: (file.size / 1048576).toFixed(2),
              extension: extension,
            });
          };
          fileReader.readAsDataURL(file);
        }
      });
    },
    dateDiffDays(fromDate, toDate) {
      let r = moment(toDate).diff(moment(fromDate), "days");
      return r > 0 ? r + 1 : 0;
    },
    getCurrentDateTime() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
    dateAddMinutes(dateTime, minutes) {
      return moment(dateTime).add(minutes, "minutes");
    },
    dateDiffMinutes(dateTimeFrom, dateTimeTo) {
      return moment(dateTimeTo).diff(moment(dateTimeFrom), "minutes");
    },
    connectWebSocket() {
      return new Promise((resolve, reject) => {
        this.chatSocket = new WebSocket(
          `https://moinak.etry.kz/ws/notification/?token=${this.userToken.access}`
        );
        this.chatSocket.onopen = function () {
          console.log("WebSocket connection opened:", event);
        };
        this.chatSocket.onmessage = function (e) {
          resolve(e);
        };
        this.chatSocket.onclose = function () {
          console.error("Chat socket closed unexpectedly");
        };

        this.chatSocket.onerror = function (error) {
          console.log("WebSocket error:", error);
          reject(error);
        };
      });
    },
  },
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    currentMonth() {
      return moment().format("MM");
    },
    currentTime() {
      return moment().format("HH:mm");
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRole() {
      return this.$store.getters["user/userRole"];
    },
    userId() {
      return this.$store.getters["user/userId"];
    },
    userToken() {
      return this.$store.getters["user/token"];
    },
    userCompanyId() {
      return this.$store.getters["user/userCompanyId"];
    },
    userCompanyRole() {
      return this.$store.getters["user/userCompanyRole"];
    },
    menu() {
      return this.$store.getters["menu/status"];
    },
    isSuperuser() {
      return !!(this.user && this.user.role === "superuser");
    },
    isAdmin() {
      return !!(
        this.user &&
        this.user.role === "admin" &&
        this.userCompanyRole === "client"
      );
    },
    isHead() {
      return !!(this.user && this.user.role === "head");
    },
    isPtd() {
      return !!(this.user && this.user.role === "ptd");
    },
    isOperationalDuty() {
      return !!(this.user && this.user.role === "operational_duty");
    },
    isCPU() {
      return !!(this.user && this.user.role === "cpu");
    },
    isWarehouseman() {
      return !!(this.user && this.user.role === "warehouseman");
    },
    isDirector() {
      return !!(this.user && this.user.role === "director");
    },
    isEngineer() {
      return !!(this.user && this.user.role === "engineer");
    },
    isPerformer() {
      return !!(this.user && this.user.role === "performer");
    },
    isAdminContractor() {
      return !!(
        this.user &&
        this.user.role === "admin" &&
        this.userCompanyRole === "contractor"
      );
    },
  },
  filters: {
    numLocaleString(val, digits) {
      if (val !== null) {
        if (typeof val === "number") {
          return digits === undefined
            ? val.toLocaleString("ru-RU")
            : val.toLocaleString("ru-RU", {
                maximumFractionDigits: digits,
              });
        } else {
          if (!isNaN(val)) {
            return digits === undefined
              ? parseFloat(val).toLocaleString("ru-RU")
              : parseFloat(val).toLocaleString("ru-RU", {
                  maximumFractionDigits: digits,
                });
          }
        }
      }
      return val;
    },
    dateParsed(date) {
      return date ? moment(date).format("D MMMM, HH:mm") : null;
    },
    dateParsedFull(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : null;
    },
    dateParsedDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : null;
    },
    dateParsedYearMonth(date) {
      return date ? moment(date).format("MMMM YYYY") : null;
    },
    dateParsedDates(dateList) {
      let r = [];
      dateList.forEach((d) => {
        r.push(moment(d).format("DD.MM.YYYY"));
      });
      return r.join(" - ");
    },
    dateParsedDateMultiple(dates) {
      if (dates) {
        let r = [];
        dates.forEach((date) => {
          r.push(moment(date).format("DD.MM.YYYY"));
        });
        return r.join(" - ");
      }
      return null;
    },
    dateParsedHour(date) {
      return date ? moment(date).format("HH:mm") : null;
    },
    truncate(text, stop, clamp) {
      if (text) {
        return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
      }
      return "";
    },
  },
});

export default {
  name: "App",
  components: {
    Loading,
  },
  data: () => ({
    parameters: {
      loading: {
        canCancel: false,
        color: "#e7e7e7",
        loader: "dots",
        width: 128,
        height: 128,
        backgroundColor: "#000",
        opacity: 0.7,
        zIndex: 10000,
      },
      notification: {
        timeout: 5000,
      },
    },
  }),
  mounted() {
    this.$store.dispatch("loading/hide");
  },
  computed: {
    loading() {
      return this.$store.getters["loading/status"];
    },
    notificationTitle() {
      return this.$store.getters["notification/title"];
    },
    notificationText() {
      return this.$store.getters["notification/text"];
    },
    notificationType() {
      return this.$store.getters["notification/type"];
    },
    notificationStatus: {
      get() {
        return this.$store.getters["notification/status"];
      },
      set() {
        this.$store.dispatch("notification/hide");
      },
    },
  },
};
</script>
